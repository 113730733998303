import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import UIDepartmentAndPersonalTreeItem from './item'

const UIDepartmentAndPersonalTree = ({
  theme,
  keyChildren,
  header,
  dataSource,
  dataOpen,
}) => {
  return (
    <div className="ui-department-and-personal-tree">
      {(header?.length > 0 ?? false) && (
        <div
          className={classNames('ui-department-and-personal-tree__header', {
            [`theme-${theme}`]: theme,
          })}
        >
          {header.map((elm, index) => (
            <div
              key={index}
              className={classNames({
                [`${elm?.className}`]: elm?.className,
              })}
            >
              {elm?.headerRender ? elm.headerRender() : elm?.name}
            </div>
          ))}
        </div>
      )}
      {(dataSource?.length > 0 ?? false) && (
        <div
          className={classNames('ui-department-and-personal-tree__body', {
            [`theme-${theme}`]: theme,
          })}
        >
          {dataSource.map((elm, indexTr) => {
            return (
              <UIDepartmentAndPersonalTreeItem
                key={indexTr}
                keyChildren={keyChildren}
                header={header}
                dataSource={elm}
                dataOpen={dataOpen}
              />
            )
          })}
        </div>
      )}
    </div>
  )
}

UIDepartmentAndPersonalTree.defaultProps = {
  theme: '',
  header: [],
  dataSource: [],
  dataOpen: [],
  keyChildren: 'children',
}

UIDepartmentAndPersonalTree.propTypes = {
  theme: PropTypes.string, // STRIPED
  header: PropTypes.array,
  dataSource: PropTypes.array,
  dataOpen: PropTypes.array,
  keyChildren: PropTypes.string,
}

export default UIDepartmentAndPersonalTree
