import React, { useMemo } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import { Button, Collapse } from '@blueprintjs/core'

import {
  contactFetchDepartmentAndPersonalTree,
  commonAddToasterMessage,
  commonFetchDepartments,
  commonFetchStaffByDepartmentId,
  getUnitItem,
  receiveDocumentSendAdditionalAssignment,
} from 'actions/index'
import UICheckbox from 'components/ui/checkbox'
import UIDepartmentAndPersonalTree from 'components/ui/department-and-personal-tree'
import useAssignWorkAdditional from './use-assign-work-additional'

const AssignWorkAdditional = ({
  actions,
  auth,
  departmentAndPersonalTree,
  receiveDocument,
  units,
  informationSteering,
  onSubmit,
  onGoBack,
}) => {
  const {
    formData,
    formError,
    phoiHopData,
    xemDeBietData,
    baoCaoData,
    disableData,
    donViPhoiHopData,
    donViXemDeBietData,
    dataOpen,
    receivingUnitSelections,
    isOpenDonViNhan,
    isShowBcCd,
    isDisabledButtonDefault,
    isSubmit,
    onFormChange,
    onChangeCheckbox,
    onChangeCheckboxDonViNhan,
    onClickArrow,
    handleClickArrowDvn,
    onFormValidate,
  } = useAssignWorkAdditional({
    actions,
    auth,
    receiveDocument,
    departmentAndPersonalTree,
    units,
    informationSteering,
    onSubmit,
  })

  const renderColumn = useMemo(() => {
    const phoiHop = disableData?.phoiHop || []
    const xemDeBiet = disableData?.xemDeBiet || []
    const baoCao = disableData?.baoCao || []
    const allDisabled = disableData?.allDisabled || []
    return [
      {
        className: 'min-width-100 flex-basis-60',
        headerRender: () => {
          return <p className="text-12-500-16">Phòng ban</p>
        },
        bodyRender: elm => {
          const isOpen = dataOpen?.includes(elm.phongBanId)
          return (
            <div className="d-flex align-items-center text-13-300-16">
              <div className="width-20">
                {elm.maPhongBan && (
                  <span
                    onClick={() => onClickArrow(elm.phongBanId)}
                    className={classNames('arrow-icon', {
                      'pt-icon pt-icon-chevron-up': isOpen,
                      'pt-icon pt-icon-chevron-down': !isOpen,
                    })}
                  ></span>
                )}
              </div>
              {elm.maPhongBan || elm.tenNhanVien}
            </div>
          )
        },
      },
      {
        className: 'min-width-60',
        headerRender: () => {
          return (
            <div className="d-flex gap-2 align-items-center">
              <span className="text-12-500-16">BSPH</span>
            </div>
          )
        },
        bodyRender: record => {
          const isPersonal = record?.chucDanhId ? true : false
          const isCheckPH = [...phoiHopData, ...phoiHop]?.some(item =>
            isPersonal
              ? item?.chucDanhId === record?.chucDanhId
              : item?.phongBanId === record?.phongBanId && !item?.chucDanhId
          )
          const isDisabled = allDisabled?.some(item =>
            isPersonal
              ? item?.chucDanhId === record?.chucDanhId
              : item?.phongBanId === record?.phongBanId && !item?.chucDanhId
          )

          return (
            <div onClick={e => e.stopPropagation()}>
              <UICheckbox
                checked={isCheckPH}
                isDisabled={isDisabled}
                onChange={e =>
                  onChangeCheckbox('PH', e.target?.checked)(record)
                }
              />
            </div>
          )
        },
      },
      {
        className: 'min-width-60',
        headerRender: () => {
          return (
            <div className="d-flex gap-2 align-items-center">
              <span className="text-12-500-16">XĐB</span>
            </div>
          )
        },
        bodyRender: record => {
          const isPersonal = record?.chucDanhId ? true : false
          const isCheckXDB = [...xemDeBietData, ...xemDeBiet]?.some(item =>
            isPersonal
              ? item?.chucDanhId === record?.chucDanhId
              : item?.phongBanId === record?.phongBanId && !item?.chucDanhId
          )
          const isDisabled = allDisabled?.some(item =>
            isPersonal
              ? item?.chucDanhId === record?.chucDanhId
              : item?.phongBanId === record?.phongBanId && !item?.chucDanhId
          )
          return (
            <div onClick={e => e.stopPropagation()}>
              <UICheckbox
                checked={isCheckXDB}
                isDisabled={isDisabled}
                onChange={e =>
                  onChangeCheckbox('XDB', e.target?.checked)(record)
                }
              />
            </div>
          )
        },
      },
      ...(isShowBcCd
        ? [
            {
              className: 'min-width-60',
              headerRender: () => {
                return (
                  <div className="d-flex gap-2 align-items-center">
                    <span className="text-12-500-16">BC</span>
                  </div>
                )
              },
              bodyRender: record => {
                const isPersonal = record?.chucDanhId ? true : false
                const isCheckBC = [...baoCaoData, ...baoCao]?.some(item =>
                  isPersonal
                    ? item?.chucDanhId === record?.chucDanhId
                    : item?.phongBanId === record?.phongBanId &&
                      !item?.chucDanhId
                )
                const isDisabled = baoCao?.some(item =>
                  isPersonal
                    ? item?.chucDanhId === record?.chucDanhId
                    : item?.phongBanId === record?.phongBanId &&
                      !item?.chucDanhId
                )
                return record?.nhanVienId && record?.phongBanLanhDao ? (
                  <div onClick={e => e.stopPropagation()}>
                    <UICheckbox
                      checked={isCheckBC}
                      isDisabled={isDisabled}
                      onChange={e =>
                        onChangeCheckbox('BC', e.target?.checked)(record)
                      }
                    />
                  </div>
                ) : (
                  <></>
                )
              },
            },
          ]
        : []),
    ]
  }, [
    onClickArrow,
    onChangeCheckbox,
    isShowBcCd,
    dataOpen,
    phoiHopData,
    xemDeBietData,
    baoCaoData,
    disableData,
  ])

  const renderColumnDonViNhan = useMemo(() => {
    const idsDonViPhoiHop = disableData?.idsDonViPhoiHop
    const idsDonViXemDeBiet = disableData?.idsDonViXemDeBiet
    const disabledDonViPhoiHop = disableData?.disabledDonViPhoiHop
    const disabledDonViXemDeBiet = disableData?.disabledDonViXemDeBiet
    return [
      {
        className: 'min-width-100 flex-basis-60',
        headerRender: () => {
          return <p className="text-12-500-16">Đơn vị</p>
        },
        bodyRender: elm => {
          return (
            <div className="d-flex align-items-center text-13-300-16">
              {elm.content}
            </div>
          )
        },
      },
      {
        className: 'min-width-60',
        headerRender: () => {
          const isChecked = donViPhoiHopData?.length > 0 ?? false
          return (
            <div className="d-flex gap-2 align-items-center">
              <UICheckbox
                checked={isChecked}
                isDisabled={false}
                onChange={onChangeCheckboxDonViNhan('PH', !isChecked, true)}
              />
              <span className="text-12-500-16">BSPH</span>
            </div>
          )
        },
        bodyRender: record => {
          const isChecked = () => {
            if (
              idsDonViPhoiHop?.some(s => s === record?.value) ||
              donViPhoiHopData?.some(s => s === record?.value)
            ) {
              return true
            }

            return false
          }
          return (
            <div onClick={e => e.stopPropagation()}>
              <UICheckbox
                checked={isChecked()}
                isDisabled={
                  disabledDonViPhoiHop?.some(s => s === record?.value) || false
                }
                onChange={e =>
                  onChangeCheckboxDonViNhan(
                    'PH',
                    e.target?.checked
                  )(record?.value)
                }
              />
            </div>
          )
        },
      },
      {
        className: 'min-width-60',
        headerRender: () => {
          const isChecked = donViXemDeBietData?.length > 0 ?? false
          return (
            <div className="d-flex gap-2 align-items-center">
              <UICheckbox
                checked={donViXemDeBietData?.length > 0 ?? false}
                isDisabled={false}
                onChange={onChangeCheckboxDonViNhan('XDB', !isChecked, true)}
              />
              <span className="text-12-500-16">XĐB</span>
            </div>
          )
        },
        bodyRender: record => {
          const isChecked = () => {
            if (
              donViXemDeBietData?.some(s => s === record?.value) ||
              idsDonViXemDeBiet?.some(s => s === record?.value)
            ) {
              return true
            }

            return false
          }
          return (
            <div onClick={e => e.stopPropagation()}>
              <UICheckbox
                checked={isChecked()}
                isDisabled={
                  disabledDonViXemDeBiet?.some(s => s === record?.value) ||
                  false
                }
                onChange={e =>
                  onChangeCheckboxDonViNhan(
                    'XDB',
                    e.target?.checked
                  )(record?.value)
                }
              />
            </div>
          )
        },
      },
    ]
  }, [
    donViPhoiHopData,
    donViXemDeBietData,
    disableData,
    onChangeCheckboxDonViNhan,
  ])

  return (
    <div className="container-form-action">
      <div className="heading">
        <span className="click-go-back" onClick={onGoBack}>
          <span className="pt-icon pt-icon-arrow-left pull-left"></span>
        </span>
        <h4 className="name-heading">Chỉ đạo bổ sung</h4>
      </div>
      <div className="form-container">
        <div className="form-assign-work p10">
          <div className="pt-form-group">
            <div className="row full-row">
              <div className="col-xs-12 col-md-12 form-item">
                <label className="pt-label">Nội dung chỉ đạo bổ sung</label>
                <div className="pt-form-content">
                  <textarea
                    name="noiDung"
                    placeholder="Nhập nội dung…"
                    dir="auto"
                    value={formData?.noiDung || ''}
                    onChange={e => onFormChange('noiDung')(e.target.value)}
                    rows="1"
                    className="pt-input pt-fill"
                    autoFocus={true}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="pt-form-group">
            <div className="form-item">
              <label className="pt-label">Phòng ban thực hiện</label>
              <UIDepartmentAndPersonalTree
                keyChildren={'dsNhanVien'}
                header={renderColumn}
                dataSource={departmentAndPersonalTree}
                dataOpen={dataOpen}
              />
            </div>
          </div>

          {/* Đơn vị nhận */}
          {isShowBcCd &&
            receivingUnitSelections &&
            receivingUnitSelections.length > 0 && (
              <div className="mt10">
                <div className="disabled-checkbox">
                  <label className="pt-label pt0">
                    <span
                      className="pull-left name_group name_group_unit"
                      onClick={handleClickArrowDvn}
                    >
                      Đơn vị nhận
                    </span>
                  </label>
                  <span
                    onClick={handleClickArrowDvn}
                    className={classNames('name_group_unit', {
                      'pt-icon pt-icon-chevron-up': isOpenDonViNhan,
                      'pt-icon pt-icon-chevron-down': !isOpenDonViNhan,
                    })}
                  ></span>
                </div>
                <Collapse
                  className="mt5"
                  keepChildrenMounted={true}
                  isOpen={isOpenDonViNhan}
                >
                  <div className="pt-form-group">
                    <UIDepartmentAndPersonalTree
                      theme={'STRIPED'}
                      header={renderColumnDonViNhan}
                      dataSource={receivingUnitSelections}
                      dataOpen={[]}
                    />
                  </div>
                </Collapse>
              </div>
            )}

          <div className="pt-form-group resolve-date pt-intent-danger mt10">
            <div className="row full-row">
              <div className="col-xs-12 col-sm-12 col-md-12 form-item">
                <label className="pt-label">Hạn giải quyết</label>
                <div className="pt-form-content w-100">
                  <div className="pt-input-group">
                    <DatePicker
                      isClearable={true}
                      readOnly
                      selected={formData?.hanGiaiQuyet}
                      onChange={onFormChange('hanGiaiQuyet')}
                      value={
                        formData?.hanGiaiQuyet ? formData?.hanGiaiQuyet : ''
                      }
                      onChangeRaw={e =>
                        onFormChange('hanGiaiQuyet')(e.target.value)
                      }
                      format="DD/MM/YYYY"
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      className="input-datepicker input-icon-datepicker"
                      placeholderText="Chọn ngày..."
                      minDate={moment()}
                      popperPlacement="auto"
                      popperModifiers={{
                        offset: {
                          enabled: true,
                          offset: '5px, 10px',
                        },
                        preventOverflow: {
                          enabled: true,
                          escapeWithReference: false,
                          boundariesElement: 'viewport',
                        },
                      }}
                    />
                  </div>
                </div>
                {formError?.hanGiaiQuyet ? (
                  <p className="pt-form-helper-text">
                    {formError?.hanGiaiQuyet}
                  </p>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </div>
        <div id="groupButton" className="buttton-action-footer">
          <Button
            disabled={isSubmit}
            onClick={onGoBack}
            className="pt-button btn-cancel"
          >
            <span className=" pt-icon icon-back"></span>
            <span className="text-content">Quay lại</span>
          </Button>
          <Button
            className="pt-button btn-blue-color"
            onClick={onFormValidate}
            loading={isSubmit}
            disabled={isDisabledButtonDefault || isSubmit}
          >
            <span className="text-content">Lưu</span>
            <span className="pt-icon icon-save"></span>
          </Button>
        </div>

        <div className="buttton-actionfooter-fixtop buttton-action-footer">
          <Button
            className="pt-button btn-blue-color"
            onClick={onFormValidate}
            loading={isSubmit}
            disabled={isDisabledButtonDefault || isSubmit}
          >
            <span className="pt-icon icon-save"></span>
            <span className="text-content">Lưu</span>
          </Button>
          <Button
            disabled={isSubmit}
            onClick={onGoBack}
            className="pt-button btn-cancel"
          >
            <span className=" pt-icon icon-back"></span>
            <span className="text-content">Quay lại</span>
          </Button>
        </div>
      </div>
    </div>
  )
}

AssignWorkAdditional.defaultProps = {
  auth: null,
  receiveDocument: null,
  onGoBack: () => {},
}

AssignWorkAdditional.propTypes = {
  auth: PropTypes.object,
  receiveDocument: PropTypes.object,
  onGoBack: PropTypes.func,
}

const mapStateToProps = state => ({
  auth: {
    ...state.auth,
    user: state.auth,
  },
  departmentAndPersonalTree: state.contact.departmentAndPersonalTree?.map(
    item => ({
      ...item,
      dsNhanVien: item?.dsNhanVien?.map(i => ({
        ...i,
        phongBanId: item.phongBanId,
        phongBanLanhDao: item?.phongBanLanhDao,
      })),
    })
  ),
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      contactFetchDepartmentAndPersonalTree,
      commonAddToasterMessage,
      commonFetchDepartments,
      commonFetchStaffByDepartmentId,
      receiveDocumentSendAdditionalAssignment,
      getUnitItem,
    },
    dispatch
  ),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssignWorkAdditional)
