import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const UIDepartmentAndPersonalTreeItem = ({
  keyChildren,
  header,
  dataSource,
  dataOpen,
}) => {
  const id = dataSource?.phongBanId
  const isOpen = useMemo(() => {
    if (dataOpen?.length === 0 ?? false) {
      return
    }

    return dataOpen?.some(s => s === id)
  }, [dataOpen, id])

  return (
    <div className="row-item">
      <div className="parent">
        {header?.length > 0 ?? false
          ? header.map((elmTd, indexTd) => (
              <div
                key={indexTd}
                className={classNames('item', {
                  [`${elmTd?.className}`]: elmTd?.className,
                })}
              >
                {elmTd?.bodyRender
                  ? elmTd.bodyRender(dataSource)
                  : elmTd?.keyName
                  ? dataSource?.[elmTd.keyName]
                  : ''}
              </div>
            ))
          : ''}
      </div>
      {isOpen &&
      dataSource?.[keyChildren] &&
      (dataSource?.[keyChildren]?.length > 0 ?? false) ? (
        <div className="child">
          {dataSource?.[keyChildren].map((elmChild, indexChildTr) => (
            <div key={indexChildTr} className="row-child">
              {header?.length > 0 ?? false
                ? header.map((elmTd, indexTd) => (
                    <div
                      key={indexTd}
                      className={classNames('item', {
                        [`${elmTd?.className}`]: elmTd?.className,
                      })}
                    >
                      {elmTd?.bodyRender
                        ? elmTd.bodyRender(elmChild)
                        : elmTd?.keyName
                        ? elmChild?.[elmTd.keyName]
                        : ''}
                    </div>
                  ))
                : ''}
            </div>
          ))}
        </div>
      ) : (
        ''
      )}
    </div>
  )
}

UIDepartmentAndPersonalTreeItem.defaultProps = {
  header: [],
  dataOpen: [],
  dataSource: null,
}

UIDepartmentAndPersonalTreeItem.propTypes = {
  header: PropTypes.array,
  dataOpen: PropTypes.array,
  dataSource: PropTypes.object,
}

export default UIDepartmentAndPersonalTreeItem
