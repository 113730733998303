import { Authorization, Permission } from '../components/auth'
import { variables } from 'constants/variables'
import {
  // HomePage,
  Login,
  InputReceiveDocumentPage,
  ListReceiveDocumentPage,
  DetailReceiveDocumentPage,
  DetailWorkPage,
  DetailPersonalWorkPage,
  DetailSeeKnowPage,
  ListPersonalWorkPage,
  DepartmentWorkNotAssigned,
  DepartmentWorkAssigned,
  ListSendSignNumberPage,
  InputDocumentSignNumberPage,
  DetailSignNumberPage,
  DetailProjectCategoryPage,
  ListInternalSignNumberPage,
  ListSendDocumentPage,
  InputSendDocumentPage,
  DetailSendDocumentPage,
  ListContact,
  AddInternalDocument,
  ListInternalDocument,
  DetailInternalDocument,
  AssignPage,
  AssignmentPage,
  AddAssignPage,
  ListDocumentsCabinet,
  WorkAuthorityPage,
  DetailDocumentsCabinet,
  AddBiddingSignNumber,
  AddFileDocumentsCabinet,
  MoveFileDocumentsCabinet,
  DecentralizationUserPage,
  RoleCategoryPage,
  UpdateDecentralizationUserPage,
  SubjectsCategoryPage,
  DepartmentCategoryPage,
  UrgencyCategoryPage,
  SecurityLevelCategoryPage,
  RegencyCategoryPage,
  RolePermissionsPage,
  SaveNoteCategoryPage,
  NotificationGeneralPage,
  AddSubjectsCategoryPage,
  AddRegencyCategoryPage,
  AddUrgencyCategoryPage,
  AddSecurityLevelCategoryPage,
  AddSaveNoteCategoryPage,
  AddDepartmentCategoryPage,
  AddRoleCategoryPage,
  DocumentarySearchPage,
  PrintSendDocumentPage,
  PrintReceiveDocumentPage,
  HandleDocumentaryPage,
  GeneralWorkPage,
  UnitCategoryPage,
  AddUnitCategoryPage,
  ListStatisticDocumentCabinetPage,
  ErrorPages,
  ListPermissionRolePage,
  SaveFileDocumentsCabinet,
  ListSeeToKnowWorkPage,
  DetailSearchDocumentPage,
  ListAlertMistakeDocumentPage,
  ListAlertMistakeWorkPage,
  ListEvnDocumentPage,
  ListGerenalDocumentOverTime,
  ListWorkDocumentOverTime,
  DocumentAuthorityPage,
  ListAssignedPersonalWorkPage,
  FollowDirections,
  ViewReport,
  DetailAssignedPersonalWorkPage,
  InputSignNumberProjectPage,
  ListSignNumberProjectsPage,
  DetailSignNumberProjectPage,
  AddProjectCategoryPage,
  ListProjectPage,
  ListStatisticalSignNumberPage,
  MoveDrawerDocumentCabinet,
  ListDocumentCabinetOverTime,
  ListStagisticalSignNumberOverTime,
  GerenalDocumentPage,
  ListStatisticUserPage,
  ListDocumentDigitize,
  ListDocumentRecovery,
  ListStatistiAlertMistakePage,
  StatisticMessengerPage,
  ListStatisticMessengerUnit,
  ListStatisticNotMessengerUnit,
  ListStatisticMessengerDepartment,
  ListStatisticSignNumberUnitDetailPage,
  MoveUsersUnitPage,
  ListRevokePage,
  DetailRevokePage,
  AddService,
  ListService,
  ThongKeService,
  StatisticService,
  TaoPhieuYeuCauGopY,
  ListPhieuYeuCauGopY,
  ListNhanYeuCauGopY,
  DetailPhieuYeuCauGopY,
  ThongKeGopY,
  OpinionList,
  OpinionDetail,
  OpinionSynthesis,
  OpinionUpdate,
  CongVanDenThuHoiDanhDach,
  DanhSachTimKiemCongVan,
  // ThoiGianLuuDanhSach,
  // ThoiGianLuuChinhSua,
  TuTaiLieuChiTiet,
  ChiTietTaiLieu,
  TuTaiLieuPhongBanThemFile,
  JobProjectStatistic,
  JobProjectUnsavedDocument,
  Priority,
  HSCVTraLai,
  PhienBan,
  Examples,
  NotFoundPage,
  // Qlcv
  // TimKiemCongVanDO,
  // DofficeCongViec,
  // DofficeCongViecChiDao,
} from '../containers/pages'

import * as Pages from '../containers/pages'
import * as Banners from '../containers/pages/HeThong'
import ChiTietTheoDoiChiDao from 'containers/pages/chi-tiet-theo-doi-chi-dao'
import ChiTietXemBaoCao from 'containers/pages/chi-tiet-xem-bao-cao'

import App from '../containers/App'
import _ from 'lodash'
const getAuthComponent = permission_list =>
  Authorization.Component(permission_list, [], true)
const getAuthAndMenuPermissionComponent = (
  permission_list,
  permissionMenu,
  isPage = true,
  isOrPermission = false
) =>
  Authorization.Component(
    permission_list,
    permissionMenu,
    isPage,
    false,
    isOrPermission
  )
// Nếu không có quyền thì chuyển về tab công việc cũ
const getAuthComponentRedirect = permission_list =>
  Authorization.Component(permission_list, [], true, true)

const ReceiveDocument = getAuthComponent([
  Permission.CONGVAN,
  Permission.CONGVANDEN,
])
const ReceiveDocumentMenuPermission = Authorization.Component(
  [Permission.CONGVAN, Permission.CONGVANDEN],
  true
)
const PrintReceiveDocument = getAuthAndMenuPermissionComponent([
  Permission.CONGVANDEN_QUANLY,
])
const SendDocument = getAuthComponent([
  Permission.CONGVAN,
  Permission.CONGVANDI,
])
const PrintSendDocument = getAuthAndMenuPermissionComponent([
  Permission.CONGVANDI_QUANLY,
])
const InternalDocument = getAuthComponent([
  Permission.CONGVAN,
  Permission.CONGVANNOIBO,
])
const DepartmentWork = getAuthAndMenuPermissionComponent([
  Permission.CONGVIEC,
  Permission.CONGVIEC_PHONGBAN,
])
const PersonalWork = getAuthAndMenuPermissionComponent([
  Permission.CONGVIEC,
  Permission.CONGVIEC_CANHAN,
])
const AssignWork = getAuthAndMenuPermissionComponent([
  Permission.CONGVIEC,
  Permission.CONGVIEC_GIAOVIEC,
])
const SignNumber = getAuthComponent([Permission.KYSO_QUANLY])
const Contact = getAuthComponent([Permission.DANHBA])
const DocumentCabinet = getAuthComponent([Permission.TUTAILIEU])
const SystemSubject = getAuthComponent([
  Permission.HETHONG,
  Permission.DOITUONGCONGVAN_QUANLY,
])
const SystemSaveNote = getAuthComponent([
  Permission.HETHONG,
  Permission.SOLUUTRU_QUANLY,
])
const SuperAdmin = getAuthComponent([Permission.SUPERADMIN])
const EvnDocument = getAuthComponent([
  Permission.CONGVAN,
  Permission.CONGVANDEN,
  Permission.CONGVANDEN_QUANLY,
])
const RequestComment = Authorization.Component(
  [Permission.LAYYKIENDONVI_PHIEUGUI],
  [],
  true
)
const ReceiveComment = Authorization.Component(
  [Permission.LAYYKIENDONVI_PHIEUNHAN],
  [],
  true
)
const DocumentAuthority = getAuthAndMenuPermissionComponent([
  Permission.CONGVAN,
  Permission.CONGVANDEN,
  Permission.CONGVANDEN_CHUYENCHIDAO,
])

const NotificationManage = getAuthAndMenuPermissionComponent([
  Permission.THONGBAO_QUANLY,
])

const GeneralDocument = getAuthAndMenuPermissionComponent([Permission.ADMIN])
const DocumentDigitize = getAuthAndMenuPermissionComponent([Permission.ADMIN])
const StatisticAlertMistake = getAuthAndMenuPermissionComponent([
  Permission.ADMIN,
])
const [System, StatisticUser, StatisticMessenger, StatisticSignNumber] = _.fill(
  Array(7),
  getAuthComponent([Permission.ADMIN])
)
const Project = getAuthComponent([Permission.DUAN_QUANLY])
const KySoTapTrungSuper = getAuthComponent([
  Permission.SUPERADMIN,
  Permission.KY_SO_TAP_TRUNG,
])
const KySoTapTrungAdmin = getAuthComponent([
  Permission.ADMIN,
  Permission.KY_SO_TAP_TRUNG,
])
const KySoTapTrung = getAuthComponent([Permission.KY_SO_TAP_TRUNG])
const TimKiemCongVanV61 = getAuthComponent([Permission.CONGVAN_XEMTATCA])
const quanLyCongViec = getAuthComponentRedirect([Permission.SUDUNG_QLCV])
const HSCVThongKe = getAuthAndMenuPermissionComponent([Permission.CONGVIEC])
const thongBaoKetLuanCuocHop = getAuthComponentRedirect([
  Permission.THONGBAOKETLUANHDTV,
])
const ThuHoiCongVanThongKe = getAuthAndMenuPermissionComponent(
  [
    Permission.ADMIN,
    Permission.CONGVANDEN_QUANLY,
    Permission.HSCV_VANPHONG_DUYET,
  ],
  true,
  true
)
const PhanQuyenChucNang = getAuthAndMenuPermissionComponent([
  Permission.SUPERADMIN,
])
const authAndMenuPermission = (permission, permissionMenu, isPage = true) =>
  Authorization.Component(permission, permissionMenu, isPage)

const routes = [
  {
    component: App,
    requireLogin: '/dang-nhap',
    routes: [
      // {
      //   path: '/work-add-sub-unit',
      //   exact: true,
      //   component: DofficeCongViec,
      // },
      // {
      //   path: '/work-direction',
      //   exact: true,
      //   component: DofficeCongViecChiDao,
      // },
      // {
      //   path: '/',
      //   exact: true,
      //   component: HomePage,
      //   privateRouter: true,
      // },
      {
        path: '/',
        exact: true,
        component: Pages.Dashboard,
        privateRouter: true,
      },
      {
        component: Login,
        path: '/dang-nhap',
        privateRouter: true,
      },
      // Cong van den
      {
        component: ReceiveDocument(AssignmentPage),
        path: '/cong-van-den/chi-dao',
        privateRouter: true,
      },
      {
        component: authAndMenuPermission([
          Permission.CONGVAN,
          Permission.CONGVANDEN,
        ])(InputReceiveDocumentPage),
        path: '/cong-van/cong-van-den/them-moi',
        privateRouter: true,
      },
      {
        component: ReceiveDocument(InputReceiveDocumentPage),
        path: '/cong-van/cong-van-den/chinh-sua/:id',
        privateRouter: true,
      },
      {
        component: ReceiveDocumentMenuPermission(ListReceiveDocumentPage),
        path: '/cong-van/cong-van-den/danh-sach/:status',
        privateRouter: true,
      },
      {
        component: ReceiveDocument(ListReceiveDocumentPage),
        path: '/cong-van/cong-van-den/danh-sach',
        privateRouter: true,
      },
      {
        component: ReceiveDocument(DetailReceiveDocumentPage),
        path: '/cong-van/cong-van-den/chi-tiet/:id',
        privateRouter: true,
      },
      {
        component: ReceiveDocument(ListAlertMistakeDocumentPage),
        path: '/cong-van/chuyen-nham/danh-sach',
        privateRouter: true,
      },
      {
        component: EvnDocument(ListEvnDocumentPage),
        path: '/cong-van/cong-van-evn/danh-sach',
        privateRouter: true,
      },
      {
        component: EvnDocument(DetailReceiveDocumentPage),
        path: '/cong-van/cong-van-evn/chi-tiet/:id/:from',
        privateRouter: true,
      },
      // Cong van di

      {
        component: authAndMenuPermission([
          Permission.CONGVAN,
          Permission.CONGVANDI,
        ])(InputSendDocumentPage),
        path: '/cong-van/cong-van-di/them-moi',
        privateRouter: true,
      },
      {
        component: SendDocument(InputSendDocumentPage),
        path: '/cong-van/cong-van-di/chinh-sua/:id',
        privateRouter: true,
      },
      {
        component: SendDocument(ListSendDocumentPage),
        path: '/cong-van/cong-van-di/danh-sach',
        privateRouter: true,
      },
      {
        component: SendDocument(DetailSendDocumentPage),
        path: '/cong-van/cong-van-di/chi-tiet/:id',
        privateRouter: true,
      },
      // Cong van noi bo

      {
        component: authAndMenuPermission([
          Permission.CONGVAN,
          Permission.CONGVANNOIBO,
        ])(AddInternalDocument),
        path: '/cong-van/cong-van-noi-bo/them-moi',
        privateRouter: true,
      },
      {
        component: InternalDocument(AddInternalDocument),
        path: '/cong-van/cong-van-noi-bo/chinh-sua/:id',
        privateRouter: true,
      },
      {
        component: InternalDocument(ListInternalDocument),
        path: '/cong-van/cong-van-noi-bo/danh-sach',
        privateRouter: true,
      },
      {
        component: InternalDocument(DetailInternalDocument),
        path: '/cong-van/cong-van-noi-bo/chi-tiet/:id',
        privateRouter: true,
      },
      // Cong van di thu hoi
      {
        component: authAndMenuPermission([Permission.CONGVAN])(ListRevokePage),
        path: '/cong-van/cong-van-di-thu-hoi/danh-sach',
        privateRouter: true,
      },
      {
        component: DetailRevokePage,
        path: '/cong-van/cong-van-di-thu-hoi/chi-tiet/:id/:idThuHoi',
        privateRouter: true,
      },
      // Công văn đến bị thu hồi
      {
        component: authAndMenuPermission([Permission.CONGVAN])(
          CongVanDenThuHoiDanhDach
        ),
        path: '/cong-van/cong-van-den-thu-hoi/danh-sach',
        privateRouter: true,
      },
      // Uy quyen cong van

      {
        component: DocumentAuthority(DocumentAuthorityPage),
        path: '/cong-van/uy-quyen',
        privateRouter: true,
      },

      // Cong viec

      {
        component: PersonalWork(AssignPage),
        path: '/cong-viec/giao-viec-moi',
        privateRouter: true,
      },
      {
        component: PersonalWork(AddAssignPage),
        path: '/cong-viec/them-moi-giao-viec',
        privateRouter: true,
      },
      {
        component: PersonalWork(ListSeeToKnowWorkPage),
        path: '/cong-viec/ca-nhan/danh-sach/xem-de-biet',
        privateRouter: true,
      },
      {
        component: PersonalWork(ListAssignedPersonalWorkPage),
        path: '/cong-viec/ca-nhan/danh-sach/da-giao',
        privateRouter: true,
      },
      {
        component: PersonalWork(FollowDirections),
        path: '/cong-viec/ca-nhan/danh-sach/theo-doi-chi-dao',
        privateRouter: true,
      },
      {
        component: PersonalWork(ViewReport),
        path: '/cong-viec/ca-nhan/danh-sach/xem-bao-cao',
        privateRouter: true,
      },
      {
        component: PersonalWork(DetailAssignedPersonalWorkPage),
        path: '/cong-viec/ca-nhan/chi-tiet/da-giao/:id',
        privateRouter: true,
      },
      {
        component: PersonalWork(ListPersonalWorkPage),
        path: '/cong-viec/ca-nhan/danh-sach/:status',
        privateRouter: true,
      },
      {
        component: PersonalWork(ListPersonalWorkPage),
        path: '/cong-viec/ca-nhan/danh-sach',
        privateRouter: true,
      },
      {
        component: DepartmentWork(DepartmentWorkNotAssigned),
        path: '/cong-viec/phong-ban/danh-sach/chua-giao',
        privateRouter: true,
      },
      {
        component: DepartmentWork(DepartmentWorkAssigned),
        path: '/cong-viec/phong-ban/danh-sach/da-giao',
        privateRouter: true,
      },
      {
        component: PersonalWork(DetailSeeKnowPage),
        path: '/cong-viec/ca-nhan/xem-de-biet/:loai/chi-tiet/:id',
        privateRouter: true,
      },
      {
        component: PersonalWork(DetailPersonalWorkPage),
        path: '/cong-viec/ca-nhan/chi-tiet/:status/:id',
        privateRouter: true,
      },
      {
        component: DepartmentWork(DetailWorkPage),
        path: '/cong-viec/phong-ban/chi-tiet/:status/:id',
        privateRouter: true,
      },
      {
        component: PersonalWork(ChiTietXemBaoCao),
        path: '/cong-viec/ca-nhan/xem-bao-cao/:loai/chi-tiet/:id',
        privateRouter: true,
      },
      {
        component: PersonalWork(ChiTietTheoDoiChiDao),
        path: '/cong-viec/ca-nhan/theo-doi-chi-dao/:loai/chi-tiet/:id',
        privateRouter: true,
      },
      {
        component: AssignWork(WorkAuthorityPage),
        path: '/cong-viec/uy-quyen',
        privateRouter: true,
      },

      {
        component: DepartmentWork(ListAlertMistakeWorkPage),
        path: '/cong-viec/chuyen-nham/danh-sach',
        privateRouter: true,
      },
      // Ky so

      {
        component: authAndMenuPermission([Permission.KYSO_QUANLY])(
          InputDocumentSignNumberPage
        ),
        path: '/ky-so/cong-van-di/them-moi',
        privateRouter: true,
      },
      {
        component: SignNumber(InputDocumentSignNumberPage),
        path: '/ky-so/cong-van-di/chinh-sua/:id',
        privateRouter: true,
      },
      {
        component: authAndMenuPermission([Permission.KYSO_QUANLY])(
          ListSendSignNumberPage
        ),
        path: '/ky-so/cong-van-di/danh-sach',
        privateRouter: true,
      },
      {
        component: authAndMenuPermission([Permission.KYSO_QUANLY])(
          DetailSignNumberPage
        ),
        path: '/ky-so/cong-van-di/chi-tiet/:id',
        privateRouter: true,
      },
      {
        component: SignNumber(InputDocumentSignNumberPage),
        path: '/ky-so/cong-van-noi-bo/them-moi',
        privateRouter: true,
      },
      {
        component: SignNumber(InputDocumentSignNumberPage),
        path: '/ky-so/cong-van-noi-bo/chinh-sua/:id',
        privateRouter: true,
      },
      {
        component: SignNumber(ListInternalSignNumberPage),
        path: '/ky-so/cong-van-noi-bo/danh-sach',
        privateRouter: true,
      },
      {
        component: SignNumber(DetailSignNumberPage),
        path: '/ky-so/cong-van-noi-bo/chi-tiet/:id',
        privateRouter: true,
      },
      {
        component: AddBiddingSignNumber,
        path: '/ky-so/cong-van-dau-thau/them-moi',
        privateRouter: true,
      },
      {
        component: ErrorPages,
        path: '/ky-so/cong-van-dau-thau/danh-sach',
        privateRouter: true,
      },
      {
        component: ErrorPages,
        path: '/ky-so/cong-van-dau-thau/chi-tiet/:id',
        privateRouter: true,
      },
      // Ky So Tap Trung
      {
        component: KySoTapTrung(Pages.SignatureDocumentList),
        path: '/ky-so-tap-trung/van-ban-ky-duyet/danh-sach',
        privateRouter: true,
      },
      {
        component: KySoTapTrung(Pages.ChiTietKySoTapTrung),
        path: '/ky-so-tap-trung/van-ban-ky-duyet/chi-tiet/:id',
        privateRouter: true,
      },
      {
        component: KySoTapTrung(Pages.ProcedureSelectPage),
        path: '/ky-so-tap-trung/van-ban-ky-duyet/them-moi',
        exact: true,
        privateRouter: true,
      },
      {
        component: KySoTapTrung(Pages.CreateSignatureDocumentPage),
        path: '/ky-so-tap-trung/van-ban-ky-duyet/them-moi/:id',
        exact: true,
        privateRouter: true,
      },
      {
        component: KySoTapTrung(Pages.EditSignatureDocumentPage),
        path: '/ky-so-tap-trung/van-ban-ky-duyet/chinh-sua/:id',
        privateRouter: true,
      },
      // KySoDuAn
      {
        component: SignNumber(InputSignNumberProjectPage),
        path: '/ky-so/du-an/them-moi',
        privateRouter: true,
      },
      {
        component: SignNumber(InputSignNumberProjectPage),
        path: '/ky-so/du-an/chinh-sua/:id',
        privateRouter: true,
      },
      {
        component: SignNumber(ListSignNumberProjectsPage),
        path: '/ky-so/du-an/danh-sach',
        privateRouter: true,
      },
      {
        component: SignNumber(DetailSignNumberProjectPage),
        path: '/ky-so/du-an/chi-tiet/:id',
        privateRouter: true,
      },

      // Hoi dong thanh vien
      {
        component: authAndMenuPermission([Permission.HDTV_LAYYKIEN])(
          OpinionList
        ),
        path: '/hoi-dong-thanh-vien/lay-y-kien/danh-sach',
        privateRouter: true,
      },
      {
        component: SignNumber(OpinionDetail),
        path: '/hoi-dong-thanh-vien/lay-y-kien/chi-tiet/:id',
        privateRouter: true,
      },
      {
        component: SignNumber(OpinionSynthesis),
        path: '/hoi-dong-thanh-vien/lay-y-kien/tong-hop/:id',
        privateRouter: true,
      },
      {
        component: SignNumber(OpinionUpdate),
        path: '/hoi-dong-thanh-vien/lay-y-kien/chinh-sua/:id',
        privateRouter: true,
      },
      // Tao Du An
      {
        component: Project(ListProjectPage),
        path: '/ky-so-mo-rong/du-an/danh-sach',
        privateRouter: true,
      },
      {
        component: Project(AddProjectCategoryPage),
        path: '/ky-so-mo-rong/du-an/them-moi',
        privateRouter: true,
      },
      {
        component: Project(AddProjectCategoryPage),
        path: '/ky-so-mo-rong/du-an/chinh-sua/:id',
        privateRouter: true,
      },
      {
        component: Project(DetailProjectCategoryPage),
        path: '/ky-so-mo-rong/du-an/thong-tin/:id',
        privateRouter: true,
      },
      // Danh ba
      {
        component: Contact(ListContact),
        path: '/danh-ba',
        privateRouter: true,
      },
      // Tu tai lieu
      {
        component: DocumentCabinet(ListDocumentsCabinet),
        path: '/tu-tai-lieu/danh-sach',
        privateRouter: true,
      },
      {
        component: DocumentCabinet(ListDocumentsCabinet),
        path: '/ho-so-cong-viec/danh-sach/:type',
        privateRouter: true,
      },
      {
        component: DocumentCabinet(TuTaiLieuChiTiet),
        path: '/ho-so-cong-viec/chi-tiet/:type/:id',
        exact: true,
        privateRouter: true,
      },
      {
        component: DocumentCabinet(TuTaiLieuPhongBanThemFile),
        path: '/ho-so-cong-viec/chi-tiet/:type/:id/them-file',
        privateRouter: true,
      },
      {
        component: DocumentCabinet(ChiTietTaiLieu),
        path: '/ho-so-cong-viec/tai-lieu/chi-tiet/:type/:id',
        privateRouter: true,
      },
      {
        component: DocumentCabinet(DetailDocumentsCabinet),
        path: '/tu-tai-lieu/chi-tiet/:type/:id',
        privateRouter: true,
      },

      {
        component: DocumentCabinet(AddFileDocumentsCabinet),
        path: '/tu-tai-lieu/them-file/:type',
        privateRouter: true,
      },

      {
        component: DocumentCabinet(MoveFileDocumentsCabinet),
        path: '/tu-tai-lieu/di-chuyen/:type',
        privateRouter: true,
      },

      {
        component: DocumentCabinet(MoveDrawerDocumentCabinet),
        path: '/tu-tai-lieu/di-chuyen-ngan-tu/:type',
        privateRouter: true,
      },
      // He Thong
      {
        component: System(DecentralizationUserPage),
        path: '/he-thong/nguoi-dung/phan-quyen',
        privateRouter: true,
      },
      {
        component: System(ListPermissionRolePage),
        path: '/he-thong/nguoi-dung/phong-ban/:id',
        privateRouter: true,
      },
      {
        component: System(MoveUsersUnitPage),
        path: '/he-thong/nguoi-dung/di-chuyen',
        privateRouter: true,
      },
      {
        component: SuperAdmin(RoleCategoryPage),
        path: '/he-thong/vai-tro/danh-muc',
        privateRouter: true,
      },
      {
        component: SystemSubject(SubjectsCategoryPage),
        path: '/he-thong/doi-tuong/danh-muc',
        privateRouter: true,
      },
      {
        component: SuperAdmin(UrgencyCategoryPage),
        path: '/he-thong/do-khan/danh-muc',
        privateRouter: true,
      },
      {
        component: SuperAdmin(SecurityLevelCategoryPage),
        path: '/he-thong/do-bao-mat/danh-muc',
        privateRouter: true,
      },
      {
        component: System(DepartmentCategoryPage),
        path: '/he-thong/phong-ban/danh-muc/:id',
        privateRouter: true,
      },
      {
        component: System(DepartmentCategoryPage),
        path: '/he-thong/phong-ban/danh-muc',
        privateRouter: true,
      },
      {
        component: SuperAdmin(UnitCategoryPage),
        path: '/he-thong/don-vi/danh-muc/:id',
        privateRouter: true,
      },
      {
        component: SuperAdmin(UnitCategoryPage),
        path: '/he-thong/don-vi/danh-muc',
        privateRouter: true,
      },
      {
        component: System(RegencyCategoryPage),
        path: '/he-thong/chuc-vu/danh-muc',
        privateRouter: true,
      },
      {
        component: SuperAdmin(RolePermissionsPage),
        path: '/he-thong/phan-quyen-vai-tro',
        privateRouter: true,
      },
      {
        component: PhanQuyenChucNang(PhienBan),
        path: '/he-thong/phien-ban/phan-quyen-chuc-nang',
        privateRouter: true,
      },
      {
        component: SystemSaveNote(SaveNoteCategoryPage),
        path: '/he-thong/so-luu/danh-muc',
        privateRouter: true,
      },
      {
        component: System(UpdateDecentralizationUserPage),
        path: '/he-thong/nguoi-dung/them-moi',
        privateRouter: true,
      },
      {
        component: System(UpdateDecentralizationUserPage),
        path: '/he-thong/nguoi-dung/chinh-sua/:id',
        privateRouter: true,
      },
      {
        component: SystemSubject(AddSubjectsCategoryPage),
        path: '/he-thong/doi-tuong/them-moi',
        privateRouter: true,
      },
      {
        component: SystemSubject(AddSubjectsCategoryPage),
        path: '/he-thong/doi-tuong/chinh-sua/:id',
        privateRouter: true,
      },
      {
        component: System(AddRegencyCategoryPage),
        path: '/he-thong/chuc-vu/them-moi',
        privateRouter: true,
      },
      {
        component: System(AddRegencyCategoryPage),
        path: '/he-thong/chuc-vu/chinh-sua/:id',
        privateRouter: true,
      },
      {
        component: SuperAdmin(AddUrgencyCategoryPage),
        path: '/he-thong/do-khan/them-moi',
        privateRouter: true,
      },
      {
        component: SuperAdmin(AddUrgencyCategoryPage),
        path: '/he-thong/do-khan/chinh-sua/:id',
        privateRouter: true,
      },
      {
        component: SuperAdmin(AddSecurityLevelCategoryPage),
        path: '/he-thong/do-bao-mat/them-moi',
        privateRouter: true,
      },
      {
        component: SuperAdmin(AddSecurityLevelCategoryPage),
        path: '/he-thong/do-bao-mat/chinh-sua/:id',
        privateRouter: true,
      },
      {
        component: SystemSaveNote(AddSaveNoteCategoryPage),
        path: '/he-thong/so-luu/them-moi',
        privateRouter: true,
      },
      {
        component: SystemSaveNote(AddSaveNoteCategoryPage),
        path: '/he-thong/so-luu/chinh-sua/:id',
        privateRouter: true,
      },
      {
        component: System(AddDepartmentCategoryPage),
        path: '/he-thong/phong-ban/them-moi',
        privateRouter: true,
      },
      {
        component: System(AddDepartmentCategoryPage),
        path: '/he-thong/phong-ban/chinh-sua/:id',
        privateRouter: true,
      },
      {
        component: SuperAdmin(AddUnitCategoryPage),
        path: '/he-thong/don-vi/them-moi',
        privateRouter: true,
      },
      {
        component: SuperAdmin(AddUnitCategoryPage),
        path: '/he-thong/don-vi/chinh-sua/:id',
        privateRouter: true,
      },
      {
        component: SuperAdmin(AddRoleCategoryPage),
        path: '/he-thong/vai-tro/them-moi',
        privateRouter: true,
      },
      {
        component: SuperAdmin(AddRoleCategoryPage),
        path: '/he-thong/vai-tro/chinh-sua/:id',
        privateRouter: true,
      },
      {
        component: NotificationManage(Banners.ListBanner),
        path: '/he-thong/quan-ly-cong-viec/banner/danh-sach',
        privateRouter: true,
      },
      {
        component: NotificationManage(Banners.UpdateBanner),
        path: '/he-thong/quan-ly-cong-viec/banner/:type/:id?',
        privateRouter: true,
      },
      {
        component: SuperAdmin(AddService),
        path: '/he-thong/service/them-moi',
        privateRouter: true,
      },
      {
        component: SuperAdmin(AddService),
        path: '/he-thong/service/chinh-sua/:id',
        privateRouter: true,
      },
      {
        component: SuperAdmin(ListService),
        path: '/he-thong/service/danh-sach',
        privateRouter: true,
      },
      {
        component: SuperAdmin(StatisticService),
        path: '/he-thong/service/thong-ke/:id',
        privateRouter: true,
      },
      {
        component: SuperAdmin(ThongKeService),
        path: '/he-thong/service/thong-ke',
        privateRouter: true,
      },
      // {
      //   component: SuperAdmin(ThoiGianLuuDanhSach),
      //   path: '/he-thong/thoi-gian-luu/danh-sach',
      //   privateRouter: true,
      // },
      // {
      //   component: SuperAdmin(ThoiGianLuuChinhSua),
      //   path: '/he-thong/thoi-gian-luu/them-moi',
      //   privateRouter: true,
      // },
      // {
      //   component: SuperAdmin(ThoiGianLuuChinhSua),
      //   path: '/he-thong/thoi-gian-luu/chinh-sua/:id',
      //   privateRouter: true,
      // },
      {
        component: SuperAdmin(Priority),
        path: '/he-thong/quan-ly-cong-viec/do-uu-tien/danh-sach',
        privateRouter: true,
      },
      // Tiện ích, lấy ý kiến đơn vị
      {
        component: RequestComment(TaoPhieuYeuCauGopY),
        path: '/tien-ich/lay-y-kien-don-vi/them-moi',
        privateRouter: true,
      },
      {
        component: RequestComment(TaoPhieuYeuCauGopY),
        path: '/tien-ich/lay-y-kien-don-vi/chinh-sua/:id',
        privateRouter: true,
      },
      {
        component: RequestComment(ListPhieuYeuCauGopY),
        path: '/tien-ich/lay-y-kien-don-vi/danh-sach-goi',
        privateRouter: true,
      },
      {
        component: RequestComment(DetailPhieuYeuCauGopY),
        path: '/tien-ich/lay-y-kien-don-vi/chi-tiet-goi/:id',
        privateRouter: true,
      },
      {
        component: ReceiveComment(ListNhanYeuCauGopY),
        path: '/tien-ich/lay-y-kien-don-vi/danh-sach-nhan',
        privateRouter: true,
      },
      {
        component: ReceiveComment(DetailPhieuYeuCauGopY),
        path: '/tien-ich/lay-y-kien-don-vi/chi-tiet-nhan/:id',
        privateRouter: true,
      },
      {
        component: ThongKeGopY,
        path: '/tien-ich/thong-ke-gop-y/:id',
        privateRouter: true,
      },
      // Tien ich
      {
        component: NotificationGeneralPage,
        path: '/tien-ich/thong-bao-chung',
        privateRouter: true,
      },
      {
        component: DocumentarySearchPage,
        path: '/tien-ich/tim-kiem-cong-van/danh-sach',
        privateRouter: true,
      },
      // {
      //   component: TimKiemCongVanDO,
      //   path: '/quan-ly-cong-viec-do/tim-kiem-cong-van',
      //   privateRouter: true,
      // },
      {
        component: TimKiemCongVanV61(DanhSachTimKiemCongVan),
        path: '/tien-ich/tim-kiem-cong-van-v61/danh-sach',
        privateRouter: true,
      },
      {
        component: DetailSearchDocumentPage,
        path: '/tien-ich/tim-kiem-cong-van/chi-tiet/:id',
        privateRouter: true,
      },
      {
        component: SaveFileDocumentsCabinet,
        path: '/tien-ich/luu-tu-tai-lieu',
        privateRouter: true,
      },
      {
        component: PrintSendDocument(PrintSendDocumentPage),
        path: '/tien-ich/in-so-cong-van-di',
        privateRouter: true,
      },
      {
        component: PrintReceiveDocument(PrintReceiveDocumentPage),
        path: '/tien-ich/in-so-cong-van-den',
        privateRouter: true,
      },
      {
        component: ListGerenalDocumentOverTime,
        path: '/tien-ich/tinh-hinh-xu-ly-cong-van/chi-tiet/phong-ban/:id',
        privateRouter: true,
      },
      {
        component: authAndMenuPermission([Permission.CONGVAN])(
          HandleDocumentaryPage
        ),
        path: '/tien-ich/tinh-hinh-xu-ly-cong-van/danh-sach/:id',
        privateRouter: true,
      },
      {
        component: authAndMenuPermission([Permission.CONGVAN])(
          HandleDocumentaryPage
        ),
        path: '/tien-ich/tinh-hinh-xu-ly-cong-van/danh-sach',
        privateRouter: true,
      },
      {
        component: ListWorkDocumentOverTime,
        path: '/tien-ich/thong-ke-cong-viec/chi-tiet/phong-ban/:phongBanId/nhan-vien/:nhanVienId/:type',
        privateRouter: true,
      },
      {
        component: authAndMenuPermission([Permission.CONGVIEC])(
          GeneralWorkPage
        ),
        path: '/tien-ich/thong-ke-cong-viec/danh-sach/:type/:id',
        privateRouter: true,
      },
      {
        component: authAndMenuPermission([Permission.CONGVIEC])(
          GeneralWorkPage
        ),
        path: '/tien-ich/thong-ke-cong-viec/danh-sach',
        privateRouter: true,
      },
      {
        component: authAndMenuPermission([Permission.CONGVIEC])(
          ListStatisticDocumentCabinetPage
        ),
        path: '/tien-ich/thong-ke-tu-tai-lieu/danh-sach',
        exact: true,
        privateRouter: true,
      },
      {
        component: authAndMenuPermission([Permission.CONGVIEC])(
          ListStatisticDocumentCabinetPage
        ),
        path: '/tien-ich/thong-ke-tu-tai-lieu/danh-sach/:type/:id',
        privateRouter: true,
      },
      {
        component: ListDocumentCabinetOverTime,
        path: '/tien-ich/thong-ke-tu-tai-lieu/chi-tiet/:type/:id/:phongBanId?',
        privateRouter: true,
      },
      {
        component: HSCVThongKe(JobProjectStatistic),
        path: '/tien-ich/thong-ke-ho-so-cong-viec/danh-sach',
        privateRouter: true,
      },
      {
        component: HSCVThongKe(JobProjectStatistic),
        path: '/tien-ich/thong-ke-ho-so-cong-viec/chi-tiet/:loai/:id',
        privateRouter: true,
      },
      {
        component: JobProjectUnsavedDocument,
        path: '/tien-ich/thong-ke-ho-so-cong-viec/cong-van-chua-luu/:id',
        privateRouter: true,
      },
      {
        component: HSCVTraLai,
        path: '/tien-ich/thong-ke-ho-so-cong-viec/tra-lai/:type?/:id?',
        privateRouter: true,
      },
      {
        component: StatisticSignNumber(ListStatisticalSignNumberPage),
        path: '/tien-ich/thong-ke-ky-so/danh-sach',
        privateRouter: true,
      },
      {
        component: StatisticSignNumber(ListStagisticalSignNumberOverTime),
        path: '/tien-ich/thong-ke-ky-so/chi-tiet/van-ban-ky-tay/:type/:id',
        privateRouter: true,
      },
      {
        component: StatisticSignNumber(ListStatisticSignNumberUnitDetailPage),
        path: '/tien-ich/thong-ke-ky-so/chi-tiet/don-vi/:id',
        privateRouter: true,
      },
      {
        component: GeneralDocument(GerenalDocumentPage),
        path: '/tien-ich/thong-ke-cong-van/danh-sach/:id',
        privateRouter: true,
      },
      {
        component: GeneralDocument(GerenalDocumentPage),
        path: '/tien-ich/thong-ke-cong-van/danh-sach',
        privateRouter: true,
      },
      {
        component: StatisticUser(ListStatisticUserPage),
        path: '/tien-ich/thong-ke-nhan-vien/danh-sach',
        privateRouter: true,
      },
      {
        component: StatisticUser(ListStatisticUserPage),
        path: '/tien-ich/thong-ke-nhan-vien/chi-tiet/don-vi/:id',
        privateRouter: true,
      },
      {
        component: DocumentDigitize(ListDocumentDigitize),
        path: '/tien-ich/thong-ke-van-ban-nhan-dang/danh-sach',
        privateRouter: true,
      },
      {
        component: ThuHoiCongVanThongKe(ListDocumentRecovery),
        path: '/tien-ich/thong-ke-thu-hoi-cong-van/danh-sach/:id',
        privateRouter: true,
      },
      {
        component: ThuHoiCongVanThongKe(ListDocumentRecovery),
        path: '/tien-ich/thong-ke-thu-hoi-cong-van/danh-sach',
        privateRouter: true,
      },
      {
        component: StatisticAlertMistake(ListStatistiAlertMistakePage),
        path: '/tien-ich/thong-ke-bao-nham/danh-sach',
        privateRouter: true,
      },
      {
        component: StatisticAlertMistake(ListStatistiAlertMistakePage),
        path: '/tien-ich/thong-ke-bao-nham/chi-tiet/:type/:id',
        privateRouter: true,
      },
      {
        component: KySoTapTrungSuper(Pages.ProcedureCategoryPage),
        path: '/he-thong/danh-muc-quy-trinh',
        exact: true,
        privateRouter: true,
      },
      {
        component: KySoTapTrungSuper(Pages.AddProcedureCategoryPage),
        path: '/he-thong/danh-muc-quy-trinh/them-moi',
        exact: true,
        privateRouter: true,
      },
      {
        component: KySoTapTrungSuper(Pages.EditProcedureCategoryPage),
        path: '/he-thong/danh-muc-quy-trinh/chinh-sua/:id',
        exact: true,
        privateRouter: true,
      },
      {
        component: KySoTapTrungSuper(Pages.AppliedUnitPage),
        path: '/he-thong/quy-trinh/:id/don-vi-ap-dung',
        exact: true,
        privateRouter: true,
      },
      {
        component: KySoTapTrungSuper(Pages.ProcedurePage),
        path: '/he-thong/quy-trinh',
        exact: true,
        privateRouter: true,
      },
      {
        component: KySoTapTrungSuper(Pages.AddProcedurePage),
        path: '/he-thong/quy-trinh/them-moi',
        exact: true,
        privateRouter: true,
      },
      {
        component: KySoTapTrungSuper(Pages.EditProcedurePage),
        path: '/he-thong/quy-trinh/chinh-sua/:id',
        exact: true,
        privateRouter: true,
      },
      {
        component: KySoTapTrungSuper(Pages.ProcedureDetailsPage),
        path: '/he-thong/quy-trinh/:id',
        exact: true,
        privateRouter: true,
      },
      {
        component: KySoTapTrungAdmin(Pages.ProcedureUsingGroupPage),
        path: '/he-thong/don-vi/quy-trinh',
        exact: true,
        privateRouter: true,
      },
      {
        component: KySoTapTrungAdmin(Pages.ProcedureDetailsPage),
        path: '/he-thong/don-vi/quy-trinh/:id',
        exact: true,
        privateRouter: true,
      },
      {
        component: KySoTapTrungAdmin(Pages.ProcedureUsingGroupDetailPage),
        path: '/he-thong/don-vi/quy-trinh/:id/nhom-thanh-vien',
        exact: true,
        privateRouter: true,
      },
      {
        component: KySoTapTrungAdmin(Pages.ProcedureUsingGroupDTXDPage),
        path: '/he-thong/don-vi/quy-trinh-dtxd/:id/nhom-thanh-vien',
        exact: true,
        privateRouter: true,
      },
      {
        component: KySoTapTrungAdmin(Pages.KsttStatisticPage),
        path: '/he-thong/thong-ke-ky-so-tap-trung/danh-sach',
        exact: true,
        privateRouter: true,
      },
      {
        component: KySoTapTrungAdmin(Pages.KsttStatisticPage),
        path: '/he-thong/thong-ke-ky-so-tap-trung/danh-sach/:id',
        exact: true,
        privateRouter: true,
      },
      {
        component: KySoTapTrung(Pages.SearchDispatch),
        path: '/ky-so-tap-trung/tim-kiem-van-ban',
        exact: true,
        privateRouter: true,
      },
      {
        component: KySoTapTrung(Pages.ChiTietKySoTapTrung),
        path: '/ky-so-tap-trung/tim-kiem-van-ban/chi-tiet/:id',
        exact: true,
        privateRouter: true,
      },
      // Thong Ke Tin Nhan
      {
        component: StatisticMessenger(StatisticMessengerPage),
        path: '/tien-ich/thong-ke-tin-nhan/danh-sach',
        privateRouter: true,
      },
      {
        component: StatisticMessenger(ListStatisticMessengerUnit),
        path: '/tien-ich/thong-ke-tin-nhan/da-gui/chi-tiet/don-vi/:id',
        privateRouter: true,
      },
      {
        component: StatisticMessenger(ListStatisticMessengerDepartment),
        path: '/tien-ich/thong-ke-tin-nhan/da-gui/chi-tiet/phong-ban/:id',
        privateRouter: true,
      },
      {
        component: StatisticMessenger(ListStatisticNotMessengerUnit),
        path: '/tien-ich/thong-ke-tin-nhan/chua-gui/chi-tiet/don-vi/:id',
        privateRouter: true,
      },
      // ThongBao
      {
        component: NotificationManage(Pages.GeneralNotifications),
        path: '/he-thong/thong-bao',
        exact: true,
        privateRouter: true,
      },
      {
        component: NotificationManage(Pages.CreateGeneralNotifications),
        path: '/he-thong/thong-bao/them-moi',
        exact: true,
        privateRouter: true,
      },
      {
        component: NotificationManage(Pages.CreateGeneralNotifications),
        path: '/he-thong/thong-bao/chinh-sua/:id',
        privateRouter: true,
      },
      {
        component: NotificationManage(Pages.DetailGeneralNotifications),
        path: '/he-thong/thong-bao/chi-tiet/:id',
        privateRouter: true,
      },

      {
        component: Pages.UserNotifications,
        path: '/he-thong/danh-sach-thong-bao',
        exact: true,
        privateRouter: true,
      },
      {
        component: Pages.DetailUserNotifications,
        path: '/he-thong/danh-sach-thong-bao/chi-tiet/:id',
        privateRouter: true,
      },
      // Quản lý công việc
      {
        component: thongBaoKetLuanCuocHop(Pages.MeetingDetail),
        path: '/thong-bao-ket-luan-cuoc-hop/chi-tiet/:id?/:action?/:actionId?',
        privateRouter: true,
      },
      {
        component: thongBaoKetLuanCuocHop(Pages.Meeting),
        path: '/quan-ly-cong-viec/thong-bao-ket-luan-cuoc-hop/:action?/:id?',
        privateRouter: true,
      },
      {
        component: thongBaoKetLuanCuocHop(Pages.ThongKeKetLuanCuocHop),
        path: '/thong-bao-ket-luan-cuoc-hop/thong-ke',
        privateRouter: true,
      },
      {
        component: quanLyCongViec(Pages.qlcvNotificationPage),
        path: '/quan-ly-cong-viec/thong-bao/',
        privateRouter: true,
      },
      {
        component: quanLyCongViec(Pages.FavoriteGroupPage),
        path: '/quan-ly-cong-viec/nhom-thuong-giao-viec/:action?/:id?',
        exact: true,
        privateRouter: true,
      },
      {
        component: quanLyCongViec(Pages.TagsPage),
        path: '/quan-ly-cong-viec/tags/:action?/:id?',
        exact: true,
        privateRouter: true,
      },
      {
        component: quanLyCongViec(Pages.qlcvStatisticPage),
        path: '/quan-ly-cong-viec/thong-ke',
        exact: true,
        privateRouter: true,
      },
      {
        component: quanLyCongViec(Pages.TimKiemCongVan),
        path: '/quan-ly-cong-viec-do/tim-kiem-cong-van/:action?/:id?',
        privateRouter: true,
      },
      {
        component: quanLyCongViec(Pages.AllTaskPage),
        path: '/quan-ly-cong-viec/:type/:action?/:id?/:actionPage?',
        exact: true,
        privateRouter: true,
      },
      {
        component: quanLyCongViec(Pages.FavoriteGroupPage),
        path: '/quan-ly-cong-viec/nhom-thuong-giao',
        privateRouter: true,
      },
      {
        component: Pages.Qlcv,
        path: '/qlcv',
      },
      // Lịch họp
      // Loại cuộc họp
      {
        component: Pages.DanhSachLoaiCuocHopPage,
        path: '/lich-hop/danh-muc/loai-cuoc-hop',
        exact: true,
        privateRouter: true,
      },
      {
        component: Pages.ThemMoiLoaiCuocHopPage,
        path: '/lich-hop/danh-muc/loai-cuoc-hop/them-moi',
        exact: true,
        privateRouter: true,
      },
      {
        component: Pages.ThemMoiLoaiCuocHopPage,
        path: '/lich-hop/danh-muc/loai-cuoc-hop/chinh-sua/:id',
        exact: true,
        privateRouter: true,
      },
      // Cấp độ cuộc họp
      {
        component: Pages.DanhSachCapDoCuocHopPage,
        path: '/lich-hop/danh-muc/cap-do-cuoc-hop',
        exact: true,
        privateRouter: true,
      },
      {
        component: Pages.ThemMoiCapDoCuocHopPage,
        path: '/lich-hop/danh-muc/cap-do-cuoc-hop/them-moi',
        exact: true,
        privateRouter: true,
      },
      {
        component: Pages.ThemMoiCapDoCuocHopPage,
        path: '/lich-hop/danh-muc/cap-do-cuoc-hop/chinh-sua/:id',
        exact: true,
        privateRouter: true,
      },
      // Nhóm vật tư
      {
        component: Pages.DanhSachNhomVatTuPage,
        path: '/lich-hop/danh-muc/nhom-vat-tu',
        exact: true,
        privateRouter: true,
      },
      {
        component: Pages.ThemMoiNhomVatTuPage,
        path: '/lich-hop/danh-muc/nhom-vat-tu/them-moi',
        exact: true,
        privateRouter: true,
      },
      {
        component: Pages.ThemMoiNhomVatTuPage,
        path: '/lich-hop/danh-muc/nhom-vat-tu/chinh-sua/:id',
        exact: true,
        privateRouter: true,
      },
      // Địa điểm họp
      {
        component: Pages.DanhSachDiaDiemHopPage,
        path: '/lich-hop/danh-muc/dia-diem-hop',
        exact: true,
        privateRouter: true,
      },
      {
        component: Pages.ThemMoiDiaDiemHopPage,
        path: '/lich-hop/danh-muc/dia-diem-hop/them-moi',
        exact: true,
        privateRouter: true,
      },
      {
        component: Pages.ThemMoiDiaDiemHopPage,
        path: '/lich-hop/danh-muc/dia-diem-hop/chinh-sua/:id',
        exact: true,
        privateRouter: true,
      },
      // Loại vật tư
      {
        component: Pages.DanhSachLoaiVatTuPage,
        path: '/lich-hop/danh-muc/loai-vat-tu',
        exact: true,
        privateRouter: true,
      },
      {
        component: Pages.ThemMoiLoaiVatTuPage,
        path: '/lich-hop/danh-muc/loai-vat-tu/them-moi',
        exact: true,
        privateRouter: true,
      },
      {
        component: Pages.ChinhSuaLoaiVatTuPage,
        path: '/lich-hop/danh-muc/loai-vat-tu/chinh-sua/:id',
        exact: true,
        privateRouter: true,
      },
      {
        component: Pages.QuanLyLichHopPage,
        path: '/lich-hop/quan-ly-lich-hop',
        exact: true,
        privateRouter: true,
      },
      {
        component: Pages.ThemMoiLichHopPage,
        path: '/lich-hop/quan-ly-lich-hop/them-moi',
        exact: true,
        privateRouter: true,
      },
      {
        component: Pages.ThemMoiLichHopPage,
        path: '/lich-hop/quan-ly-lich-hop/chinh-sua/:id',
        exact: true,
        privateRouter: true,
      },
      {
        component: Pages.ChiTietLichHopPage,
        path: '/lich-hop/quan-ly-lich-hop/chi-tiet/:id',
        exact: true,
        privateRouter: true,
      },
      {
        component: Pages.ChiTietLichHopNguoiThamGiaPage,
        path: '/lich-hop/chi-tiet-nguoi-tham-gia/:id',
        exact: true,
        privateRouter: true,
      },
      {
        component: Pages.CapNhatHauCanPage,
        path: '/lich-hop/hau-can/cap-nhat/:id',
        exact: true,
        privateRouter: true,
      },
      {
        component: Pages.ChiTietLichHopHauCanPage,
        path: '/lich-hop/hau-can/chi-tiet/:id',
        exact: true,
        privateRouter: true,
      },
      {
        component: Pages.LichHopCaNhanPage,
        path: '/lich-hop/ca-nhan',
        exact: true,
        privateRouter: true,
      },
      {
        component: Pages.LichHopHauCanPage,
        path: '/lich-hop/hau-can',
        exact: true,
        privateRouter: true,
      },
      {
        component: Pages.ChatModule,
        path: variables.CHAT_URL,
        exact: true,
        privateRouter: true,
      },
      {
        component: Examples,
        path: '/examples',
        exact: true,
        privateRouter: true,
      },
      {
        path: '/error-page',
        exact: true,
        component: ErrorPages,
        privateRouter: true,
      },
      {
        path: '*',
        exact: true,
        component: NotFoundPage,
      },
    ],
  },
]

export default routes
