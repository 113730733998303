import React, { useMemo } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import { Button, Checkbox, Collapse, Radio } from '@blueprintjs/core'

import {
  contactFetchDepartmentAndPersonalTree,
  commonAddToasterMessage,
  commonFetchDepartments,
  commonFetchStaffByDepartmentId,
  fileUpload,
  postChiDaoGiaoViec,
  assignmentHistoryDelete,
  deleteWorkingDocument,
  updateAssignmentHistory,
  getUnitItem,
} from 'actions/index'
import UICheckbox from 'components/ui/checkbox'
import { DepartmentPerform, PopupConfirm } from 'components/common'
import UIDepartmentAndPersonalTree from 'components/ui/department-and-personal-tree'
import useAssignWork from './use-assign-work'
import * as POPUP from 'constants/Popup'

const AssignWork = ({
  actions,
  auth,
  departmentAndPersonalTree,
  receiveDocument,
  onGoBack,
  onSuccess,
  units,
  status,
  congVanDenId,
  informationSteering,
  isUpdateAssignment,
  onSubmit,
}) => {
  const {
    formData,
    formError,
    isLoading,
    chuTriData,
    phoiHopData,
    xemDeBietData,
    chiDaoData,
    baoCaoData,
    dataOpen,
    receivingUnitSelections,
    isOpenDonViNhan,
    departmentAssigned,
    isOpenPopupDelete,
    isShowBcCd,
    onFormChange,
    onChangeGridCheckBox,
    onChangeCheckbox,
    onClickArrow,
    // getAllChucDanhIds,
    handleClickArrowDvn,
    handleClickPopupDelete,
    handleSubmitRemoveItem,
    handleSubmitReWorking,
    handleSubmit,
  } = useAssignWork({
    actions,
    auth,
    receiveDocument,
    onSuccess,
    departmentAndPersonalTree,
    units,
    congVanDenId,
    informationSteering,
    isUpdateAssignment,
    onSubmit,
  })

  const isChuTriChecked = useMemo(
    () => data => {
      if (!chuTriData) {
        return false
      }

      if (data.phongBanId && !data.chucDanhId && !chuTriData.chucDanhId) {
        if (chuTriData?.phongBanId === data?.phongBanId) {
          return true
        }
        return false
      } else {
        if (chuTriData?.chucDanhId === data?.chucDanhId) {
          return true
        }
        return false
      }
    },
    [chuTriData]
  )

  const isChiDaoChecked = useMemo(
    () => data => {
      if (!chiDaoData) {
        return false
      }

      if (
        chiDaoData?.chucDanhId &&
        data?.chucDanhId &&
        chiDaoData?.chucDanhId === data?.chucDanhId
      ) {
        return true
      }

      return false
    },
    [chiDaoData]
  )

  const renderColumn = useMemo(() => {
    return [
      {
        className: 'min-width-100 flex-basis-60',
        headerRender: () => {
          return <p className="text-12-500-16">Phòng ban</p>
        },
        bodyRender: elm => {
          const isOpen = dataOpen?.includes(elm.phongBanId)
          return (
            <div className="d-flex align-items-center text-13-300-16">
              <div className="width-20">
                {elm.maPhongBan && (
                  <span
                    onClick={() => onClickArrow(elm.phongBanId)}
                    className={classNames('arrow-icon', {
                      'pt-icon pt-icon-chevron-up': isOpen,
                      'pt-icon pt-icon-chevron-down': !isOpen,
                    })}
                  ></span>
                )}
              </div>
              {elm.maPhongBan || elm.tenNhanVien}
            </div>
          )
        },
      },
      {
        className: 'min-width-60',
        headerRender: () => {
          return <p className="text-12-500-16">CT</p>
        },
        bodyRender: record => {
          const isCTChecked = isChuTriChecked(record)
          return (
            <div
              onClick={e => {
                e.preventDefault()
                return onChangeCheckbox('CT', !isCTChecked)(record)
              }}
            >
              <Radio
                className="pt-normal m0 pl0"
                checked={isCTChecked}
                readOnly
              />
            </div>
          )
        },
      },
      {
        className: 'min-width-60',
        headerRender: () => {
          return (
            <div className="d-flex gap-2 align-items-center">
              <span className="text-12-500-16">PH</span>
            </div>
          )
        },
        bodyRender: record => {
          const isPersonal = record?.chucDanhId ? true : false
          const isCheckPH = phoiHopData?.some(item =>
            isPersonal
              ? item?.chucDanhId === record?.chucDanhId
              : item?.phongBanId === record?.phongBanId && !item?.chucDanhId
          )
          return (
            <div onClick={e => e.stopPropagation()}>
              <UICheckbox
                checked={isCheckPH}
                onChange={e =>
                  onChangeCheckbox('PH', e.target?.checked)(record)
                }
              />
            </div>
          )
        },
      },
      {
        className: 'min-width-60',
        headerRender: () => {
          return (
            <div className="d-flex gap-2 align-items-center">
              <span className="text-12-500-16">XĐB</span>
            </div>
          )
        },
        bodyRender: record => {
          const isPersonal = record?.chucDanhId ? true : false
          const isCheckXDB = xemDeBietData?.some(item =>
            isPersonal
              ? item?.chucDanhId === record?.chucDanhId
              : item?.phongBanId === record?.phongBanId && !item?.chucDanhId
          )
          return (
            <div onClick={e => e.stopPropagation()}>
              <UICheckbox
                checked={isCheckXDB}
                onChange={e =>
                  onChangeCheckbox('XDB', e.target?.checked)(record)
                }
              />
            </div>
          )
        },
      },
      ...(isShowBcCd
        ? [
            {
              className: 'min-width-40',
              headerRender: () => {
                return <p className="text-12-500-16">CĐ</p>
              },
              bodyRender: record => {
                const isChecked = isChiDaoChecked(record)
                return record?.nhanVienId && record?.phongBanLanhDao ? (
                  <div
                    onClick={e => {
                      e.preventDefault()
                      return onChangeCheckbox('CD', !isChecked)(record)
                    }}
                  >
                    <Radio
                      className="pt-normal m0 pl0"
                      checked={isChecked}
                      readOnly
                    />
                  </div>
                ) : (
                  <></>
                )
              },
            },
            {
              className: 'min-width-60',
              headerRender: () => {
                return (
                  <div className="d-flex gap-2 align-items-center">
                    <span className="text-12-500-16">BC</span>
                  </div>
                )
              },
              bodyRender: record => {
                const isCheckBC = baoCaoData?.includes(record?.chucDanhId)
                return record?.nhanVienId && record?.phongBanLanhDao ? (
                  <div onClick={e => e.stopPropagation()}>
                    <UICheckbox
                      checked={isCheckBC}
                      onChange={e =>
                        onChangeCheckbox('BC', e.target?.checked)(record)
                      }
                    />
                  </div>
                ) : (
                  <></>
                )
              },
            },
          ]
        : []),
    ]
  }, [
    isChuTriChecked,
    isChiDaoChecked,
    onClickArrow,
    onChangeCheckbox,
    isShowBcCd,
    dataOpen,
    phoiHopData,
    xemDeBietData,
    baoCaoData,
  ])

  return (
    <div className="container-form-action">
      <div className="heading">
        <span className="click-go-back" onClick={onGoBack}>
          <span className="pt-icon pt-icon-arrow-left pull-left"></span>
        </span>
        <h4 className="name-heading">
          {status
            ? 'Giao lại'
            : informationSteering
            ? 'Sửa/Hủy chỉ đạo'
            : 'Chỉ đạo'}
        </h4>
      </div>
      <div className="form-container">
        <div className="form-assign-work p10">
          <div className="pt-form-group">
            <div className="row full-row">
              <div className="col-xs-12 col-md-12 form-item">
                <label className="pt-label">Nội dung chỉ đạo</label>
                <div className="pt-form-content">
                  <textarea
                    name="noiDung"
                    placeholder="Nhập nội dung…"
                    dir="auto"
                    value={formData?.noiDung || ''}
                    onChange={e => onFormChange('noiDung')(e.target.value)}
                    rows="1"
                    className="pt-input pt-fill"
                    autoFocus={true}
                  />
                </div>
              </div>
            </div>
          </div>

          <UIDepartmentAndPersonalTree
            keyChildren={'dsNhanVien'}
            header={renderColumn}
            dataSource={departmentAndPersonalTree}
            dataOpen={dataOpen}
          />

          {/* Đơn vị nhận */}
          {isShowBcCd &&
            receivingUnitSelections &&
            receivingUnitSelections.length > 0 && (
              <div className="mt10">
                <div className="disabled-checkbox">
                  <label className="pt-label pt0">
                    <span
                      className="pull-left name_group name_group_unit"
                      onClick={handleClickArrowDvn}
                    >
                      Đơn vị nhận
                    </span>
                  </label>
                  <span
                    onClick={handleClickArrowDvn}
                    className={classNames('name_group_unit', {
                      'pt-icon pt-icon-chevron-up': isOpenDonViNhan,
                      'pt-icon pt-icon-chevron-down': !isOpenDonViNhan,
                    })}
                  ></span>
                </div>
                <Collapse className="mt5" isOpen={isOpenDonViNhan}>
                  <div className="pt-form-group">
                    <DepartmentPerform
                      options={receivingUnitSelections}
                      onSubmit={onChangeGridCheckBox('units')}
                      dsPhoiHop={formData?.dsDonViPhoiHop}
                      dsXemDeBiet={formData?.dsDonViXemDeBiet}
                      chuTriId={formData?.donViChuTriId}
                      selectedCompositiveDepartments={[]}
                      selectedOnlySeeDepartments={[]}
                      titles={['CT', 'PH', 'XĐB']}
                      isHasRadio={true}
                      type="Đơn vị"
                    />
                  </div>
                </Collapse>
              </div>
            )}

          <div className="pt-form-group resolve-date pt-intent-danger mt10">
            <div className="row full-row">
              <div className="col-xs-12 col-sm-12 col-md-12 form-item">
                <label className="pt-label">Hạn giải quyết</label>
                <div className="pt-form-content w-100">
                  <div className="pt-input-group">
                    <DatePicker
                      isClearable={true}
                      readOnly
                      selected={formData?.hanGiaiQuyet}
                      onChange={onFormChange('hanGiaiQuyet')}
                      value={formData?.hanGiaiQuyet}
                      onChangeRaw={e =>
                        onFormChange('hanGiaiQuyet')(e.target.value)
                      }
                      format="DD/MM/YYYY"
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      className="input-datepicker input-icon-datepicker"
                      placeholderText="Chọn ngày..."
                      minDate={moment()}
                      popperPlacement="auto"
                      popperModifiers={{
                        offset: {
                          enabled: true,
                          offset: '5px, 10px',
                        },
                        preventOverflow: {
                          enabled: true,
                          escapeWithReference: false,
                          boundariesElement: 'viewport',
                        },
                      }}
                    />
                  </div>
                </div>
                <p className="text-13-300-i opacity-07 mt5">
                  Nếu không chọn LĐ Phòng ban chủ trì sẽ xác định
                </p>
                {formError?.hanGiaiQuyet ? (
                  <p className="pt-form-helper-text">
                    {formError?.hanGiaiQuyet}
                  </p>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>

          <div className="selectbox-group">
            <div className="row full-row">
              <div className="col-xs-12 col-md-9 col-form-item">
                <Checkbox
                  className="pt-inline"
                  name="luuBanGoc"
                  checked={formData?.luuBanGoc || false}
                  onChange={e => onFormChange('luuBanGoc')(e.target.checked)}
                >
                  <span className="name_checkbox">
                    Phòng ban chủ trì lưu văn bản giấy
                  </span>
                </Checkbox>
              </div>
            </div>
          </div>
          {/* Khi công văn đã được giao việc thì hiện dòng này*/}
          {departmentAssigned && (
            <p className="note-highline note-qoute-left">
              Chú ý công văn này đã được Lãnh đạo Phòng/Ban giao việc
            </p>
          )}
        </div>
        {/* Footer */}
        {!status && (
          <div id="groupButton" className="buttton-action-footer">
            {informationSteering && (
              <Button
                onClick={handleClickPopupDelete}
                className="pt-button pull-left btn-cancel btn-cancel-steering"
                disabled={
                  Object.values(isLoading).find(item => item === true) !==
                  undefined
                }
                loading={isLoading && isLoading.isLoadingRemove === true}
              >
                <span className="text-content">HUỶ CHỈ ĐẠO</span>
                <span className=" pt-icon pt-icon-trash"></span>
              </Button>
            )}
            <Button
              onClick={onGoBack}
              className="pt-button btn-cancel"
              disabled={
                Object.values(isLoading).find(item => item === true) !==
                undefined
              }
            >
              <span className=" pt-icon icon-back"></span>
              <span className="text-content">Quay lại</span>
            </Button>
            <Button
              className="pt-button btn-blue-color"
              onClick={handleSubmit}
              loading={isLoading && isLoading.isLoadingSubmit === true}
              disabled={
                Object.values(isLoading).find(item => item === true) !==
                  undefined ||
                (informationSteering && departmentAssigned)
              }
            >
              <span className="text-content">Lưu</span>
              <span className="pt-icon icon-save"></span>
            </Button>
          </div>
        )}

        {!status && (
          <div className="buttton-actionfooter-fixtop buttton-action-footer">
            <Button
              className="pt-button btn-blue-color"
              onClick={handleSubmit}
              loading={isLoading && isLoading.isLoadingSubmit === true}
              disabled={
                Object.values(isLoading).find(item => item === true) !==
                  undefined ||
                (informationSteering && departmentAssigned)
              }
            >
              <span className="pt-icon icon-save"></span>
              <span className="text-content">Lưu</span>
            </Button>
            <Button
              onClick={onGoBack}
              className="pt-button btn-cancel"
              disabled={
                Object.values(isLoading).find(item => item === true) !==
                undefined
              }
            >
              <span className=" pt-icon icon-back"></span>
              <span className="text-content">Quay lại</span>
            </Button>
          </div>
        )}

        {status && (
          <div id="groupButton" className="buttton-action-footer">
            <Button
              onClick={onGoBack}
              className="pt-button btn-cancel"
              disabled={
                Object.values(isLoading).find(item => item === true) !==
                undefined
              }
            >
              <span className=" pt-icon icon-back"></span>
              <span className="text-content">Quay lại</span>
            </Button>
            <Button
              className="pt-button btn-blue-color"
              onClick={handleClickPopupDelete}
              loading={isLoading && isLoading.isLoadingSubmit === true}
              disabled={
                Object.values(isLoading).find(item => item === true) !==
                undefined
              }
            >
              <span className="text-content">Giao lại</span>
              <span className="pt-icon icon-save"></span>
            </Button>
          </div>
        )}

        {status && (
          <div className="buttton-actionfooter-fixtop buttton-action-footer">
            <Button
              className="pt-button btn-blue-color"
              onClick={handleClickPopupDelete}
              loading={isLoading && isLoading.isLoadingSubmit === true}
              disabled={
                Object.values(isLoading).find(item => item === true) !==
                undefined
              }
            >
              <span className="pt-icon icon-save"></span>
              <span className="text-content">Giao lại</span>
            </Button>

            <Button
              onClick={onGoBack}
              className="pt-button btn-cancel"
              disabled={
                Object.values(isLoading).find(item => item === true) !==
                undefined
              }
            >
              <span className=" pt-icon icon-back"></span>
              <span className="text-content">Quay lại</span>
            </Button>
          </div>
        )}

        {/* Hủy chỉ đạo */}
        <PopupConfirm
          isOpen={isOpenPopupDelete}
          onClose={handleClickPopupDelete}
          title={!status ? POPUP.HUY_CHI_DAO : POPUP.HUY_GIAO_LAI}
          text={POPUP.TEXT_BUTTON_SUBMIT}
          onSubmit={!status ? handleSubmitRemoveItem : handleSubmitReWorking}
        >
          {!status
            ? POPUP.TEXT_SUBMIT_BODY
            : POPUP.TEXT_SUBMIT_BODY_ALERTMISTAKE_DOCUMENT}
        </PopupConfirm>
      </div>
    </div>
  )
}

AssignWork.defaultProps = {
  auth: null,
  departments: [],
  staffs: [],
  receiveDocument: null,
  onGoBack: () => {},
  onSuccess: () => {},
}

AssignWork.propTypes = {
  auth: PropTypes.object,
  departments: PropTypes.array,
  staffs: PropTypes.array,
  receiveDocument: PropTypes.object,
  onGoBack: PropTypes.func,
  onSuccess: PropTypes.func,
}

const mapStateToProps = state => ({
  auth: {
    ...state.auth,
    user: state.auth,
  },
  departments: state.common.departments,
  staffs: state.common.staffs,
  departmentAndPersonalTree: state.contact.departmentAndPersonalTree?.map(
    item => ({
      ...item,
      dsNhanVien: item?.dsNhanVien?.map(i => ({
        ...i,
        phongBanId: item.phongBanId,
        phongBanLanhDao: item?.phongBanLanhDao,
      })),
    })
  ),
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      contactFetchDepartmentAndPersonalTree,
      commonAddToasterMessage,
      commonFetchDepartments,
      commonFetchStaffByDepartmentId,
      fileUpload,
      postChiDaoGiaoViec,
      assignmentHistoryDelete,
      deleteWorkingDocument,
      updateAssignmentHistory,
      getUnitItem,
    },
    dispatch
  ),
})

export default connect(mapStateToProps, mapDispatchToProps)(AssignWork)
